import React, { Component } from 'react'
import { withUiContext } from '../../UiContext'
import * as videos from '../../const/videos'
import './BackVideo.css'

const BgVideo = videos.BG
const VIDEO_WIDTH = 16
const VIDEO_HEIGHT = 9

class BackVideo extends Component{
  state = {
		width: 0,
		height: 0
	}

	componentDidMount() {
		this.setSize()
		window.addEventListener('resize', this.setSize)
	}

	handleClick = () => {
		const { ui } = this.props
		ui.toggleMenu()
	}

	setSize = () => {
		const windowWidth = window.innerWidth
		const windowHeight = window.innerHeight
		let s_width = windowWidth
		let width, height
		let expectedHeight = s_width / VIDEO_WIDTH * VIDEO_HEIGHT
		// if ( expectedHeight < windowHeight ) {
		if ( windowHeight - expectedHeight > windowHeight ) {
			height = windowHeight
			width = windowHeight / VIDEO_HEIGHT * VIDEO_WIDTH
		} else {
			width = s_width
			height = expectedHeight
		}
		this.setState({width: width, height: height})
	}
  render(){
  	const { width, height } = this.state
  	const { ui } = this.props
	  return(
	  	<div>
				<iframe className="bg-video" src={"https://player.vimeo.com/video/"+ BgVideo.vimeo_id +"?background=1&autoplay=1"}
					style={{width: width, height: height}}
					width="1920" height="1080" 
					frameborder="0" allow="autoplay; fullscreen" allowfullscreen
					>
				</iframe>
				<div className="bg-video-overlay" onClick={this.handleClick}></div>
		  </div>
	  )
  }
}

// <video className="bg-video" src={BgVideo.file} playsInline 
//       webkit-playsinline="true" 
//       loop={true} autoPlay={true} />

export default withUiContext(BackVideo)