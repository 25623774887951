// const prefix = process.env.PUBLIC_URL + '/video/'
const prefix = '/video/'

export const PRESENTATION = {
	file: prefix + 'Gusar 10_2019.mp4',
	vimeo_id: '363155413',
	title: '',
	name: ''
}
export const SCHEME = {
	file: prefix + 'Plexus_blue sound.mp4',
	vimeo_id: '363386400',
	title: '',
	name: ''
}
export const UNDERWATER = {
	file: prefix + 'Gusar_intro.mp4',
	vimeo_id: '367096115',
	title: '',
	name: ''
}

export const MUDLINE_360 = {
	file: prefix + 'Sborka.webm',
	title: '',
	name: 'MUDLINE_360'
}

export const DETAIL1_360 = {
	file: prefix + 'd8.webm',
	title: '',
	name: 'DETAIL1_360'
}
export const DETAIL2_360 = {
	file: prefix + 'd7.webm',
	title: '',
	name: 'DETAIL2_360'
}
export const DETAIL3_360 = {
	file: prefix + 'd1.webm',
	title: '',
	name: 'DETAIL3_360'
}
export const DETAIL4_360 = {
	file: prefix + 'd2.webm',
	title: '',
	name: 'DETAIL4_360'
}
export const DETAIL5_360 = {
	file: prefix + 'd3.webm',
	title: '',
	name: 'DETAIL5_360'
}
export const DETAIL6_360 = {
	file: prefix + 'd4.webm',
	title: '',
	name: 'DETAIL6_360'
}
export const DETAIL7_360 = {
	file: prefix + 'd6.webm',
	title: '',
	name: 'DETAIL7_360'
}
export const DETAIL8_360 = {
	file: prefix + 'd5.webm',
	title: '',
	name: 'DETAIL8_360'
}

export const BG = {
	file: prefix + 'crane2_1.mp4',
	vimeo_id: '367291680',
	// vimeo_id: '367080109',
	title: '',
	name: ''
}
