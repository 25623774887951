export const HOME = '/'

export const MUDLINE = '/mudline/'
export const MUDLINE_DETAILS = '/mudline/:details'
export const MUDLINE_DESC = '/mudline-description'
export const MUDLINE_360 = '/mudline360'

export const SCHEME = '/scheme'

export const UNDERWATER = '/underwater'

export const ABOUT = '/about'
export const GALLERY = '/gallery'
export const VIDEO = '/video'

export const DETAILS_DETAIL1 = '/detail1'
export const DETAILS_DETAIL2 = '/detail2'
export const DETAILS_DETAIL3 = '/detail3'
export const DETAILS_DETAIL4 = '/detail4'
export const DETAILS_DETAIL5 = '/detail5'
export const DETAILS_DETAIL6 = '/detail6'
export const DETAILS_DETAIL7 = '/detail7'
export const DETAILS_DETAIL8 = '/detail8'

export const DETAIL1_DESC = '/detail1/description'
export const DETAIL2_DESC = '/detail2/description'
export const DETAIL3_DESC = '/detail3/description'
export const DETAIL4_DESC = '/detail4/description'
export const DETAIL5_DESC = '/detail5/description'
export const DETAIL6_DESC = '/detail6/description'
export const DETAIL7_DESC = '/detail7/description'
export const DETAIL8_DESC = '/detail8/description'

export const DETAIL3_360 = '/detail3/360'
export const DETAIL4_360 = '/detail4/360'