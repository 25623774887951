import React, { Component } from 'react'
import CircleBtn from '../../components/CircleBtn'
import BackVideo from '../../components/BackVideo'
import * as routes from '../../const/routes'

export default class Home extends Component{
	
  render(){
	  return(
	  	<div className="home-screen">
		  	<CircleBtn link={routes.UNDERWATER} />
		  	<BackVideo />
		  </div>
	  )
  }
}