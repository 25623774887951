import React from 'react'
import NavLink from '../../components/NavLink'
import IconCircleBtn from '../Common/Icons/IconCircleBtn'
import './CircleBtn.css'

import Img from '../../assets/img/mudline.png'

export default ({link, ...props}) => (
  <NavLink to={link} className="circle-btn">
  	<IconCircleBtn />
  	<img src={Img} alt="" className="circle-btn__img"/>
  	<div className="circle-btn__text">СИСТЕМА <i>MUDLINE</i></div>
  </NavLink>
)