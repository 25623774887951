import React, { Component } from 'react';
import cx from 'classnames'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import Menu from '../Menu'
import MobilePlaceholder from '../MobilePlaceholder'
import BackSound from '../BackSound'
import IconMenuBtn from '../Common/Icons/IconMenuBtn'
import Preloader from '../Preloader'
import * as videos from '../../const/videos'

import UiContext from '../../UiContext'

import './Layout.css'

import backAudio from '../../assets/audio/backSound.mp3'
import overAudio from '../../assets/audio/Over2.mp3'
import overAudio1 from '../../assets/audio/Over1.mp3'
import clickAudio from '../../assets/audio/Click2.mp3'
import clickAudio1 from '../../assets/audio/Click1.mp3'



class Layout extends Component {
	constructor(props) {
    super(props);
    
    this.toggleDarkBg = () => {
      this.setState(state => ({darkBg: !state.darkBg}));
    }

    this.toggleNav = (e) => {
			this.setState(prevState => ({navExpanded: !prevState.navExpanded}))
		}

		this.playBackAudio = () => {
	    let playPromise = this.backAudio.play()
			if (playPromise !== 'undefined') {
		    playPromise.then(()=>{
		    	this.setState({backAudioPaused: false})
		    })
		    .catch(error => {})
		  }
	  }
		this.pauseBackAudio = () => {
	    this.backAudio.pause()
	    this.setState({backAudioPaused: true})
	  }
		this.toggleBackAudio = () => {
	    this.backAudio.paused ? this.playBackAudio() : this.pauseBackAudio() 
	  }

		this.playOverSound = () => {
	    let playPromise = this.overSound.play()
			if (playPromise !== 'undefined') {
		    playPromise.then(()=>{})
		    .catch(error => {})
		  }
	  }
	  this.playClickSound = () => {
	    let playPromise = this.clickSound.play()
			if (playPromise !== 'undefined') {
		    playPromise.then(()=>{})
		    .catch(error => {})
		  }
	  }
		this.playOverSound1 = () => {
	    let playPromise = this.overSound1.play()
			if (playPromise !== 'undefined') {
		    playPromise.then(()=>{})
		    .catch(error => {})
		  }
	  }
	  this.playClickSound1 = () => {
	    let playPromise = this.clickSound1.play()
			if (playPromise !== 'undefined') {
		    playPromise.then(()=>{})
		    .catch(error => {})
		  }
	  }
	  this.toggleMenu = (e) => {
			this.setState(prevState => ({menuOpened: !prevState.menuOpened}))
		}
		this.enableBackAudioUserControl = () => {
			this.setState({isBackAudioMutedByUser: true})
		}
		this.disableBackAudioUserControl = () => {
			this.setState({isBackAudioMutedByUser: false})
		}
    
    this.state = {
    	menuOpened: false,
      loaded: false,
      darkBg: false,
      toggleDarkBg: this.toggleDarkBg,
      navExpanded: false,
      backAudioPaused: true,
      isBackAudioMutedByUser: false,
      toggleNav: this.toggleNav,
      playOverSound: this.playOverSound,
		  playClickSound: this.playClickSound,
		  playOverSound1: this.playOverSound1,
		  playClickSound1: this.playClickSound1,
		  playBackAudio: this.playBackAudio,
		  pauseBackAudio: this.pauseBackAudio,
		  toggleBackAudio: this.toggleBackAudio,
		  toggleMenu: this.toggleMenu,
		  enableBackAudioUserControl: this.enableBackAudioUserControl,
		  disableBackAudioUserControl: this.disableBackAudioUserControl
    }
  }

  componentDidMount = () => {
		this.playBackAudio()
	}

	handlePageClick = (e) => {
		if (e.target != this.page) return
		e.stopPropagation()
		this.toggleMenu()
	}

	handleLoad = () => {
		this.setState({loaded: true})
	}

	closeWindow = () => {
		window.close()
	}

	render() {
		const {
			children, 
			header = true, 
			footer = true, 
			pageClassName = '',
			isNav = false,
			isBackBtn = false,
			isHomePage = false,
		} = this.props
		const { menuOpened, loaded, navExpanded, darkBg } = this.state
		return (
			<UiContext.Provider value={this.state}>
				<div 
					className={cx(
						"page", 
						pageClassName, 
						menuOpened && "_menu-opened", 
						navExpanded && "_nav-expanded", 
						loaded && "_loaded",
						darkBg && "_dark-bg",
					)}
					onClick={this.handlePageClick}
					ref={(page) => this.page = page}
				>
					<audio src={backAudio} ref={(backAudio) => this.backAudio = backAudio} loop={true} />
					<audio src={overAudio} 
          	ref={(overSound) => this.overSound = overSound} />
	        <audio src={clickAudio} 
	          ref={(clickSound) => this.clickSound = clickSound} />
					<audio src={overAudio1} 
	          ref={(overSound1) => this.overSound1 = overSound1} />
	        <audio src={clickAudio1} 
	          ref={(clickSound1) => this.clickSound1 = clickSound1} />

	        {!loaded &&
	        	<Preloader onLoad={this.handleLoad} />
	        }
	        {loaded && 
	        	<div className="_bg-inherit">
							<TransitionGroup className="menu-outer">
								{menuOpened && 
			        		<CSSTransition timeout={400} classNames="menu-transition">
										<Menu />
									</CSSTransition>					
								}
							</TransitionGroup>
							<div className="logo"></div>
							<div className="right-buttons">
								<BackSound />
							</div>
							<div className="menu-btn" onClick={this.toggleMenu}><IconMenuBtn /></div>
							{children}
						</div>
					}
						
					<MobilePlaceholder />
				</div>
			</UiContext.Provider>
		)
	}
}

export default Layout
