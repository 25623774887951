import React from 'react'
import cx from 'classnames'
import Modal from '../../components/Modal'
import Backdrop from '../../components/Backdrop'
import CloseBtn from '../../components/CloseBtn'
import ScreenTitle from '../../components/ScreenTitle'
import NavLink from '../../components/NavLink'
import * as routes from '../../const/routes'
import './About.css'

import GalleryThumb from '../../assets/img/about-photo.jpg'
import VideoThumb from '../../assets/img/about-video.jpg'

export default ({PLACEHOLDER, ...props}) => (
	<div className="_bg-inherit">
		<ScreenTitle>
			<span>О ЗАВОДЕ <i>ГУСАР</i></span>
		</ScreenTitle>
	  <Modal className={cx("about-screen")}>
			<Backdrop >
				<div className="about">
					<NavLink to={routes.GALLERY} className="about__item">
						<div className="about__text">ФОТО ЗАВОДА</div>
						<div className="about__img"><img src={GalleryThumb} alt=""/></div>
					</NavLink>
					<NavLink to={routes.VIDEO} className="about__item">
						<div className="about__text">ВИДЕОПРЕЗЕНТАЦИЯ ЗАВОДА</div>
						<div className="about__img"><img src={VideoThumb} alt="" /></div>
					</NavLink>
				</div>
			</Backdrop>
			<CloseBtn link={routes.HOME} />
	  </Modal>
  </div>
)