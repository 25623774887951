import React, { Component } from 'react'
import { withUiContext } from '../../UiContext'
import CloseBtn from '../../components/CloseBtn'
import * as routes from '../../const/routes'
import './Gallery.css'
import '../../assets/scss/lib/slick.min.css'

import Slider from "react-slick"

import img1 from '../../assets/img/gal/gallery1.jpg'
import img1Thumb from '../../assets/img/gal/gallery1.jpg'
import img2 from '../../assets/img/gal/gallery2.jpg'
import img2Thumb from '../../assets/img/gal/gallery2.jpg'
import img3 from '../../assets/img/gal/gallery3.jpg'
import img3Thumb from '../../assets/img/gal/gallery3.jpg'
import img4 from '../../assets/img/gal/gallery4.jpg'
import img4Thumb from '../../assets/img/gal/gallery4.jpg'
import img5 from '../../assets/img/gal/gallery5.jpg'
import img5Thumb from '../../assets/img/gal/gallery5.jpg'
import img6 from '../../assets/img/gal/gallery6.jpg'
import img6Thumb from '../../assets/img/gal/gallery6.jpg'
import img7 from '../../assets/img/gal/gallery7.jpg'
import img7Thumb from '../../assets/img/gal/gallery7.jpg'
import img8 from '../../assets/img/gal/gallery8.jpg'
import img8Thumb from '../../assets/img/gal/gallery8.jpg'
import img9 from '../../assets/img/gal/gallery9.jpg'
import img9Thumb from '../../assets/img/gal/gallery9.jpg'
import img10 from '../../assets/img/gal/gallery10.jpg'
import img10Thumb from '../../assets/img/gal/gallery10.jpg'
import img11 from '../../assets/img/gal/gallery11.jpg'
import img11Thumb from '../../assets/img/gal/gallery11.jpg'
import img12 from '../../assets/img/gal/gallery12.jpg'
import img12Thumb from '../../assets/img/gal/gallery12.jpg'

class Gallery extends Component{
	state = {
		nav: false,
		main: false,
	}
	componentDidMount() {
		this.setState({
			nav: this.nav,
			main: this.main
		})
		window.addEventListener('keyup', this.handleKey)
		let arrows = document.getElementsByClassName('slick-arrow')
		console.log(arrows)
		if (arrows && arrows.length) {
			arrows[0].addEventListener('click', this.playClickSound)
			arrows[0].addEventListener('mouseenter', this.handleMouseEnter)
			arrows[1].addEventListener('click', this.playClickSound)
			arrows[1].addEventListener('mouseenter', this.handleMouseEnter)
		}
	}
	componentWillUnmount() {
		window.removeEventListener('keyup', this.handleKey)
	}
	handleNavClick = (e) => {
		let index = parseInt(e.target.dataset.index, 10)
		this.nav.slickGoTo(index - 1)
		this.main.slickGoTo(index - 1)
		this.playClickSound()
	}
	handleKey = (e) => {
		let key = e.key
		if (key === 'ArrowRight') {
			this.main.slickNext()
		} else if (key === 'ArrowLeft') {
			this.main.slickPrev()
		}
		this.playClickSound()
	}
	handleMouseEnter = () => {
    const {ui} = this.props
    ui.playOverSound()
  }
  playClickSound = () => {
  	const {ui} = this.props
    ui.playClickSound()	
  }
  render(){
  	let settingsMain = {
      dots: false,
      arrows: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      asNavFor: this.nav,
    }
    
		let settingsNav = {
		  dots: false,
		  infinite: true,
		  speed: 500,
		  slidesToShow: 6,
		  slidesToScroll: 1,
		  asNavFor: this.main,
		}
		
	  return(
	  	<div className="gallery">
	  		<CloseBtn out={true} noBg={true} link={routes.ABOUT} />
	  		<div className="gallery__main">
	  			{/*<div className="gallery__header">Заголовок галереи</div>*/}
		  		<Slider {...settingsMain} ref={(main) => this.main = main}>
		  			<img src={img1} alt=""/>
		  			<img src={img2} alt=""/>
		  			<img src={img3} alt=""/>
		  			<img src={img4} alt=""/>
		  			<img src={img5} alt=""/>
		  			<img src={img6} alt=""/>
		  			<img src={img7} alt=""/>
		  			<img src={img8} alt=""/>
		  			<img src={img9} alt=""/>
		  			<img src={img10} alt=""/>
		  			<img src={img11} alt=""/>
		  			<img src={img12} alt=""/>
		  		</Slider>
	  		</div>
	  		<div className="gallery__nav">
	  			<Slider {...settingsNav} ref={(nav) => this.nav = nav}>
		  			<img src={img1Thumb} alt="" data-index={1} onClick={this.handleNavClick} onMouseEnter={this.handleMouseEnter}/>
		  			<img src={img2Thumb} alt="" data-index={2} onClick={this.handleNavClick} onMouseEnter={this.handleMouseEnter}/>
		  			<img src={img3Thumb} alt="" data-index={3} onClick={this.handleNavClick} onMouseEnter={this.handleMouseEnter}/>
		  			<img src={img4Thumb} alt="" data-index={4} onClick={this.handleNavClick} onMouseEnter={this.handleMouseEnter}/>
		  			<img src={img5Thumb} alt="" data-index={5} onClick={this.handleNavClick} onMouseEnter={this.handleMouseEnter}/>
		  			<img src={img6Thumb} alt="" data-index={6} onClick={this.handleNavClick} onMouseEnter={this.handleMouseEnter}/>
		  			<img src={img7Thumb} alt="" data-index={7} onClick={this.handleNavClick} onMouseEnter={this.handleMouseEnter}/>
		  			<img src={img8Thumb} alt="" data-index={8} onClick={this.handleNavClick} onMouseEnter={this.handleMouseEnter}/>
		  			<img src={img9Thumb} alt="" data-index={9} onClick={this.handleNavClick} onMouseEnter={this.handleMouseEnter}/>
		  			<img src={img10Thumb} alt="" data-index={10} onClick={this.handleNavClick} onMouseEnter={this.handleMouseEnter}/>
		  			<img src={img11Thumb} alt="" data-index={11} onClick={this.handleNavClick} onMouseEnter={this.handleMouseEnter}/>
		  			<img src={img12Thumb} alt="" data-index={12} onClick={this.handleNavClick} onMouseEnter={this.handleMouseEnter}/>
		  		</Slider>
	  		</div>
	  	</div>
	  );
  }
}

export default withUiContext(Gallery)

