import React, { Component } from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import cx from 'classnames'
import Backdrop from '../../components/Backdrop'
import Item from '../../components/Item'
import CloseBtn from '../../components/CloseBtn'
import DetailsBtn from '../../components/DetailsBtn'
import Modal from '../../components/Modal'
import Detail from '../../components/Detail'
import ScreenTitle from '../../components/ScreenTitle'
import DescriptionBtn from '../../components/DescriptionBtn'
import * as routes from '../../const/routes'
import * as videos from '../../const/videos'

import Img from '../../assets/img/mudline.png'
import DetailImg1 from '../../assets/img/detail1.png'
import DetailImg2 from '../../assets/img/detail2.png'
import DetailImg3 from '../../assets/img/detail3.png'
import DetailImg4 from '../../assets/img/detail4.png'
import DetailImg5 from '../../assets/img/detail5.png'
import DetailImg6 from '../../assets/img/detail6.png'
import DetailImg7 from '../../assets/img/detail7.png'
import DetailImg8 from '../../assets/img/detail8.png'

const Video = videos.MUDLINE_360

export default class Mudline extends Component{
	state = {
		showDetails: 2
	}
	componentDidMount() {
		this.videoSrc = document.getElementById("video_url_" + Video.name).value
	}
	toggleDetails = () => {
		const { match } = this.props
		let _match =  match.params.details == 'details'
		this.setState((prevState) => {
			if (prevState.showDetails === 2) {
				return _match ? {showDetails: 0} : {showDetails: 1}
			} else {
				return prevState.showDetails === 0 ? {showDetails: 1} : {showDetails: 0}
			}
		})
	}
  render(){
  	const { showDetails } = this.state
  	const { match } = this.props
  	let _showDetails = showDetails === 2 ? match.params.details == 'details' : showDetails
	  return(
	  	<div className="_bg-inherit">
	  		<ScreenTitle>
	  			<span>СИСТЕМА придонных ПОДВЕСОК <i>MUDLINE</i></span>
	  		</ScreenTitle>
		  	<Modal className={cx( "mudline-screen", _showDetails && "_show-details" )}>
				  <Backdrop>
				  	<Item 
				  		name="Система придонных подвесок Mudline" 
				  		img={Img} 
				  		descLink={routes.MUDLINE_DESC}
				  		isDetail={false}
				  		video={this.videoSrc}
				  		>
				  	</Item>
				  </Backdrop>
				  <CloseBtn link={routes.HOME} />
				  <DetailsBtn onClick={this.toggleDetails} />
				  <DescriptionBtn link={routes.MUDLINE_DESC} />
				  <TransitionGroup className="details-outer">
				  	{_showDetails &&
		          <CSSTransition timeout={400} classNames="detail-transition">
		          	<div className="details-outer">
								  <Detail link={routes.DETAILS_DETAIL3} img={DetailImg3} name="Посадочное кольцо 30”" posX="left" posY="1" />
								  <Detail link={routes.DETAILS_DETAIL4} img={DetailImg4} name="Устьевой трубодержатель (подвеска) обсадной колонны 20”" posX="left" posY="2" />
								  <Detail link={routes.DETAILS_DETAIL5} img={DetailImg5} name="Спуско-подъемный инструмент трубодержателя (подвески) обсадной колонны 20”" posX="left" posY="3" />
								  <Detail link={routes.DETAILS_DETAIL6} img={DetailImg6} name="Устьевой трубодержатель (подвеска) обсадной колонны 13-3/8”" posX="left" posY="4" />

								  <Detail link={routes.DETAILS_DETAIL8} img={DetailImg8} name="Спуско-подъемный инструмент трубодержателя (подвески) обсадной колонны 13-3/8”" posX="right" posY="1" />
								  <Detail link={routes.DETAILS_DETAIL7} img={DetailImg7} name="Устьевой трубодержатель (подвеска) обсадной колонны 9-5/8”" posX="right" posY="2" />
								  <Detail link={routes.DETAILS_DETAIL2} img={DetailImg2} name="Спуско-подъемный инструмент трубодержателя (подвески) обсадной колонны 9-5/8”" posX="right" posY="3" />
								  <Detail link={routes.DETAILS_DETAIL1} img={DetailImg1} name="Консервационный колпак 13-3/8” x 9-5/8”" posX="right" posY="4" />
							  </div>
					  	</CSSTransition>
					  }
				  </TransitionGroup>
			  </Modal>
		  </div>
	  )
  }
}