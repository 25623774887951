import React from 'react'
import cx from 'classnames'
import './Modal.css'

export default ({children, className, ...props}) => (
	<div className={cx("modal-outer", className)}>
	  <div className={cx("modal")}>
	  	{children}
	  </div>
  </div>
)