import React from 'react'
import cx from 'classnames'
import Backdrop from '../../components/Backdrop'
import CloseBtn from '../../components/CloseBtn'
import Item from '../../components/Item'
import Modal from '../../components/Modal'
import ScreenTitle from '../../components/ScreenTitle'
import DescriptionBtn from '../../components/DescriptionBtn'
import Btn360 from '../../components/Btn360'
import * as routes from '../../const/routes'
import * as videos from '../../const/videos'

const Video = videos.DETAIL4_360

export default () => (
  <div className="_bg-inherit">
  	<ScreenTitle>
			<span>СИСТЕМА придонных ПОДВЕСОК <i>MUDLINE</i></span>
		</ScreenTitle>
		<Modal className={cx( "detail-screen")}>
		  <Backdrop>
		  	<Item wide={true} descLink={routes.DETAIL4_DESC} name="Устьевой трубодержатель (подвеска) обсадной колонны 20”" video={Video}></Item>
		  </Backdrop>
		  <CloseBtn link={routes.DETAILS_DETAIL4} />
		  <DescriptionBtn link={routes.DETAIL4_DESC} detailDescBtn={true} />
  	</Modal>
  </div>
)