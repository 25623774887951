import React from 'react'
import cx from 'classnames'
import NavLink from '../../components/NavLink'
import './Detail.css'

export default ({link, img, name, posY, posX, ...props}) => (
  <div className={cx("detail", "detail_"+posX, "detail_"+posY)}>
  	<NavLink to={link} className="detail__inner">
  		<div className="detail__img">
  			<img src={img} alt="" />
  		</div>
  		<div className="detail__name">{name}</div>
  	</NavLink>
  </div>
)