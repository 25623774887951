import React, { Component } from 'react'
import cx from 'classnames'
import NavLink from '../../components/NavLink'
import Btn360 from '../../components/Btn360'
import './Item.css'

export default class Item extends Component {
  toggleVideo = () => {
    const videoEl = this.videoEl
    if (!videoEl) return
    videoEl.paused ? videoEl.play() : videoEl.pause()
  }

  render(){
    const { name, img, video, wide, description, descLink, isDetail=true } = this.props
    return(
      <div className={cx("item", wide && "item_wide")}>
        {video &&
          <Btn360 wide={wide} onClick={this.toggleVideo} />
        }
        {img && !video &&
          <div className="item__img">
            <NavLink to={descLink}>
              <img src={img} alt=""/>
            </NavLink>
          </div>
        }
        {video && 
          <div className="item__video" onClick={this.toggleVideo}>
            <video src={video} playsInline 
              webkit-playsinline="true" 
              ref={(videoEl) => this.videoEl = videoEl}
              loop={true} />
          </div>
        }
        <NavLink to={descLink}>
          <div className="item__name">{name}</div>
          <div className="item__description">{description}</div>
        </NavLink>
      </div>
    )
  }
}