import React, { Component } from 'react'
import cx from 'classnames'
import { withUiContext } from '../../UiContext'
import Icon360 from '../Common/Icons/Icon360'
import NavLink from '../../components/NavLink'
import './Btn360.css'

class Btn360 extends Component{
	handleClick = () => {
		const { onClick, ui } = this.props
		onClick()
		ui.playClickSound()
	}
  render(){
  	const { link, wide, ui } = this.props
	  return(
	  	<div className={cx("btn360", wide && "btn360_wide")} onClick={this.handleClick} onMouseEnter={ui.playOverSound}>
	  		{link &&
			  	<NavLink to={link} className="btn360__inner">
			  		<Icon360 />
			  	</NavLink>
			  }
			  {!link &&
					<span className="btn360__inner">
						<Icon360 />
					</span>	
			  }
		  </div>
	  )
  }
}

export default withUiContext(Btn360)