import React from 'react'
import NavLink from '../NavLink'
import * as routes from '../../const/routes'
import './Menu.css'

import Menu1Img from '../../assets/img/mudline.png'
import Menu2Img from '../../assets/img/menu2.png'
import Menu3Img from '../../assets/img/menu3.png'

export default () => (
  <div className="menu">
  	<div className="menu__item">
  		<NavLink to="/mudline/web" className="menu__item-inner">
	  		<div className="menu__img menu__img_1"><img src={Menu1Img} alt=""/></div>
	  		<div className="menu__text">СИСТЕМА ПРИДОННЫХ ПОДВЕСОК MUDLINE</div>
	  	</NavLink>
  	</div>
  	<div className="menu__item">
  		<NavLink to={routes.SCHEME} className="menu__item-inner">
	  		<div className="menu__img menu__img_2"><img src={Menu2Img} alt=""/></div>
	  		<div className="menu__text">СХЕМА РАБОТЫ Mudline</div>
	  	</NavLink>
  	</div>
  	<div className="menu__item">
  		<NavLink to={routes.ABOUT} className="menu__item-inner">
	  		<div className="menu__img menu__img_3"><img src={Menu3Img} alt=""/></div>
	  		<div className="menu__text">О ЗАВОДЕ ГУСАР</div>
	  	</NavLink>
  	</div>
  </div>
)