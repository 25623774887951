import React from 'react';
import { BrowserRouter as Router, Route, Switch, withRouter } from 'react-router-dom'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import Layout from './components/Layout'
import * as routes from './const/routes'

import Home from './screens/Home'
import Mudline from './screens/Mudline'
import MudlineDescription from './screens/MudlineDescription'
import Mudline360 from './screens/Mudline/Mudline360'
import About from './screens/About'
import Gallery from './screens/Gallery'
import Scheme from './screens/Scheme'
import Underwater from './screens/Underwater'
import VideoScreen from './screens/VideoScreen'
import Detail1 from './screens/Details/Detail1'
import Detail2 from './screens/Details/Detail2'
import Detail3 from './screens/Details/Detail3'
import Detail4 from './screens/Details/Detail4'
import Detail5 from './screens/Details/Detail5'
import Detail6 from './screens/Details/Detail6'
import Detail7 from './screens/Details/Detail7'
import Detail8 from './screens/Details/Detail8'
import Detail1Desc from './screens/Details/Detail1Desc'
import Detail2Desc from './screens/Details/Detail2Desc'
import Detail3Desc from './screens/Details/Detail3Desc'
import Detail4Desc from './screens/Details/Detail4Desc'
import Detail5Desc from './screens/Details/Detail5Desc'
import Detail6Desc from './screens/Details/Detail6Desc'
import Detail7Desc from './screens/Details/Detail7Desc'
import Detail8Desc from './screens/Details/Detail8Desc'
import Detail3360 from './screens/Details/Detail3360'
import Detail4360 from './screens/Details/Detail4360'

// const withLayout = (Component) => (props) => <Layout><Component {...props} /></Layout>

function App({location, ...props}) {
  return (
    <Router>
      <Layout>
        <Route render={({ location }) => (
          <TransitionGroup className="_bg-inherit">
            <CSSTransition key={location.key} timeout={400} classNames="modal-transition">
              <Switch location={location}>
                <Route exact path={routes.HOME} component={Home} />
                <Route path={routes.MUDLINE_DETAILS} component={Mudline} />
                <Route path={routes.MUDLINE_DESC} component={MudlineDescription} />
                <Route path={routes.MUDLINE_360} component={Mudline360} />
                <Route exact path={routes.SCHEME} component={Scheme} />
                <Route exact path={routes.UNDERWATER} component={Underwater} />
                <Route exact path={routes.ABOUT} component={About} />
                <Route exact path={routes.GALLERY} component={Gallery} />
                <Route exact path={routes.VIDEO} component={VideoScreen} />

                <Route exact path={routes.DETAILS_DETAIL1 } component={Detail1} />
                <Route exact path={routes.DETAILS_DETAIL2} component={Detail2} />
                <Route exact path={routes.DETAILS_DETAIL3} component={Detail3} />
                <Route exact path={routes.DETAILS_DETAIL4} component={Detail4} />
                <Route exact path={routes.DETAILS_DETAIL5} component={Detail5} />
                <Route exact path={routes.DETAILS_DETAIL6} component={Detail6} />
                <Route exact path={routes.DETAILS_DETAIL7} component={Detail7} />
                <Route exact path={routes.DETAILS_DETAIL8} component={Detail8} />

                <Route exact path={routes.DETAIL1_DESC } component={Detail1Desc} />
                <Route exact path={routes.DETAIL2_DESC } component={Detail2Desc} />
                <Route exact path={routes.DETAIL3_DESC } component={Detail3Desc} />
                <Route exact path={routes.DETAIL4_DESC } component={Detail4Desc} />
                <Route exact path={routes.DETAIL5_DESC } component={Detail5Desc} />
                <Route exact path={routes.DETAIL6_DESC } component={Detail6Desc} />
                <Route exact path={routes.DETAIL7_DESC } component={Detail7Desc} />
                <Route exact path={routes.DETAIL8_DESC } component={Detail8Desc} />

                <Route exact path={routes.DETAIL3_360 } component={Detail3360} />
                <Route exact path={routes.DETAIL4_360 } component={Detail4360} />
              </Switch>
            </CSSTransition>
          </TransitionGroup>
        )} />
      </Layout>
    </Router>
  );
}

export default App;
