import React, { Component } from 'react'
import Backdrop from '../../components/Backdrop'
import * as videos from '../../const/videos'
import './Preloader.css'

import Bg from '../../assets/img/bg2.jpg'
import LogoImg from '../../assets/img/logo2.png'
import Menu1Img from '../../assets/img/mudline.png'
import Menu2Img from '../../assets/img/menu2.png'
import Menu3Img from '../../assets/img/menu3.png'
const preload_images = [
	Bg,
	LogoImg,
	Menu1Img,
	Menu2Img,
	Menu3Img
]
const preload_videos = [
	videos.MUDLINE_360,
	videos.DETAIL1_360,
	videos.DETAIL2_360,
	videos.DETAIL3_360,
	videos.DETAIL4_360,
	videos.DETAIL5_360,
	videos.DETAIL6_360,
	videos.DETAIL7_360,
	videos.DETAIL8_360
]

export default class Preloader extends Component{
	state = {
		loop_loaded: false,
		stage2_loaded: false,
		progress: 0,
		error: '',
		videoError : ''
	}
	componentDidMount() {
		// this.preloadImages()
		// preload_videos.forEach((video, index) => {
		// 	this.preloadVideo(video, index)
		// })
		this.preloadVideo(preload_videos[0], 0)
	}
	preloadImages = () => {
		// let imagesPromise = new Promise((resolve, reject) => {
		// 	this.preloadImage(preload_images[0], 0, resolve)
		// })
		preload_images.forEach((image, index) => {
			let _image = new Image()
			_image.src = image
		})
	}
	// preloadImage = (image, index, imagesPromiseResolve) => {
	// 	let imagePromise = new Promise((resolve, reject) => {
	// 		console.log('preloadImage', image)
	// 		let _image = new Image()
	// 		_image.src = image
	// 		_image.onLoad = () => {
	// 			console.log('_image onLoad')
	// 			resolve()
	// 		}
	// 	})
	// 	if (index >= preload_images.length - 1) {
	// 		imagesPromiseResolve()
	// 		return
	// 	}
	// 	imagePromise.then(() => {
	// 		this.preloadImage(preload_images[index + 1], index + 1)
	// 	})
	// }
	preloadVideo = (video, index) => {
		const _this = this
		const { onLoad } = this.props
		let videoPromise = new Promise((resolve, reject) => {
			const videoUrl = video.file
			var req = new XMLHttpRequest();
			req.open('GET', videoUrl, true);
			req.responseType = 'blob';

			const videoUrlInput = document.createElement('input')
			videoUrlInput.id = "video_url_" + video.name
			videoUrlInput.type = "hidden"
			document.body.insertBefore(videoUrlInput, document.body.childNodes[0])

			req.onprogress = (progress) => {
				// this.setState({progress: "Progress: " + progress})
			}
			req.onload = function() {
			   // Onload is triggered even on 404
			   // so we need to check the status code

			   // console.log('req.onload', videoUrlInput)
			   if (this.status === 200) {
			      var videoBlob = this.response;
			      var vid = URL.createObjectURL(videoBlob); // IE10+
			      videoUrlInput.value = vid;

			      _this.setState((prevState) => {
			      	let p =  prevState.progress + Math.ceil(100 / preload_videos.length)
			      	// console.log('p', p)
			      	if (p > 100) p = 100
			      	return {progress: p}
			      })
			      resolve()
			      
			   }
			}
			req.onerror = function(e) {
			  _this.setState({error: "ajax error" + e.name + ":" + e.message})
			  console.log('req.error ' + e.name + ":" + e.message)
			}

			req.send()
		})
		if (index >= preload_videos.length - 1) {
			onLoad()
			return
		}
		videoPromise.then(() => {
			this.preloadVideo(preload_videos[index + 1], index + 1)
		})
	}
  render(){
  	const { progress } = this.state
	  return(
	  	<div className="preloader">
	  		<Backdrop>
	  			<div className="preloader__inner">
		  			<div className="preloader__logo"></div>
		  			<div className="preloader__progress"><span style={{width: progress + '%'}}></span></div>
		    		<span className="preloader__num">{progress}%</span>
	    		</div>
	    	</Backdrop>
	  	</div>
	  )
  }
}