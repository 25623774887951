import React, { Component } from 'react'
import { withUiContext } from '../../UiContext'
import IconDetails from '../Common/Icons/IconDetails'
import IconDetailsActive from '../Common/Icons/IconDetailsActive'
import './DetailsBtn.css'

class DetailsBtn extends Component{
	handleClick = () => {
		const { onClick, ui } = this.props
		onClick()
		ui.playClickSound()
	}
  render(){
  	const { ui } = this.props
	  return(
	  	<div className="details-btn" onClick={this.handleClick} onMouseEnter={ui.playOverSound}>
	  		<div className="details-btn__text">Детали</div>
		  	<IconDetails />
		  	<span className="details-btn__active">
		  		<IconDetailsActive />
		  	</span>
		  </div>
	  )
  }
}

export default withUiContext(DetailsBtn)