import React from 'react'
import ScreenTitle from '../../components/ScreenTitle'
import OverModal from '../../components/OverModal'
import CloseBtn from '../../components/CloseBtn'
import Description from '../../components/Description'
import * as routes from '../../const/routes'

export default () => (
	<div className="_bg-inherit">
		<ScreenTitle rotate={true}>
			<span>СИСТЕМА придонных ПОДВЕСОК <i>MUDLINE</i></span>
		</ScreenTitle>
	  <OverModal className="video-screen">
			<Description>
				<CloseBtn link={routes.DETAILS_DETAIL6} noBg={true} />
				<h1>Устьевой трубодержатель (подвеска) обсадной колонны 13-3/8”</h1> 
		  	<div className="subt"></div>
		  	<p>
					Подвеска 13-3/8” обсадной колонны оснащена двумя резьбами для спускоподъёмного инструмента и надставочного переводника и в системе придонной подвески выполняет следующие функции:
					<ul>
						<li>Поддерживает собственный вес в системе подвески</li>
						<li>Поддерживает вес последующих колонн</li>
						<li>Обеспечивает доступ из затрубного пространства к устью скважины</li>
						<li>Обеспечивает циркуляцию бурового раствора и цемента во время спуска и посадки предыдущей подвески</li>
						<li>Позволяет установку спускового инструмента, соединительного узла водоотделяющей колонны и подводного оборудования</li>
						<li>Обеспечивает хождение обсадной колонны во время цементажа</li>
					</ul>
				</p>
			</Description>
	  </OverModal>
  </div>
)
