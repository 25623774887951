import React from 'react'

export default () => (
  <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clip-path="url(#clip0)">
			<path d="M37.492 1.01364C36.7751 1.01364 36.194 1.59486 36.194 2.3117V4.64405C32.2835 1.63248 27.5175 0 22.5 0C16.49 0 10.8398 2.34044 6.59004 6.59004C2.34035 10.8397 0 16.49 0 22.5C0 28.5101 2.34044 34.1604 6.59022 38.4101C6.84369 38.6637 7.17592 38.7904 7.50806 38.7904C7.8402 38.7904 8.17251 38.6636 8.42599 38.4101C8.93285 37.9032 8.93285 37.0813 8.42599 36.5744C4.66655 32.8149 2.59611 27.8167 2.59611 22.5C2.59611 11.525 11.525 2.59611 22.5 2.59611C26.6563 2.59611 30.6179 3.86147 33.9453 6.20991H32.2997C31.5828 6.20991 31.0017 6.79113 31.0017 7.50797C31.0017 8.2248 31.5829 8.80603 32.2997 8.80603H37.492C37.5335 8.80603 37.5744 8.80357 37.6149 8.79979C37.6153 8.79979 37.6158 8.79979 37.6162 8.7997C37.6523 8.79627 37.6876 8.79064 37.7227 8.7844C37.7289 8.78326 37.7351 8.78273 37.7413 8.78159C37.7756 8.77491 37.8091 8.76621 37.8424 8.7569C37.8496 8.75487 37.8569 8.75347 37.8641 8.75127C37.8964 8.7416 37.928 8.73009 37.9592 8.71796C37.9673 8.71488 37.9756 8.71225 37.9838 8.70899C38.0145 8.69643 38.0442 8.6821 38.0737 8.66725C38.0821 8.66303 38.0907 8.65942 38.0991 8.65503C38.13 8.63868 38.1597 8.62058 38.1891 8.60177C38.1954 8.59772 38.2021 8.59421 38.2084 8.59008C38.3536 8.49375 38.4783 8.36912 38.5745 8.22393C38.5787 8.2176 38.5822 8.21092 38.5863 8.2045C38.605 8.17515 38.623 8.14544 38.6394 8.11468C38.6438 8.10624 38.6475 8.09763 38.6517 8.08919C38.6665 8.05975 38.6808 8.03013 38.6934 7.99945C38.6967 7.99128 38.6993 7.98302 38.7024 7.97476C38.7145 7.94355 38.726 7.912 38.7357 7.87966C38.7378 7.87271 38.7391 7.8656 38.741 7.85865C38.7504 7.82517 38.7592 7.79133 38.7659 7.75679C38.767 7.75134 38.7675 7.74571 38.7685 7.74026C38.7822 7.66468 38.7904 7.58733 38.7904 7.50788V2.3117C38.7901 1.59478 38.2089 1.01364 37.492 1.01364Z" fill="white"/>
			<path d="M14.9089 22.5C16.0683 22.0771 16.8538 20.9736 16.8538 19.7169C16.8538 18.0828 15.5244 16.7534 13.8904 16.7534H9.62444C8.90751 16.7534 8.32638 17.3346 8.32638 18.0515C8.32638 18.7683 8.9076 19.3495 9.62444 19.3495H13.8904C14.0929 19.3495 14.2577 19.5143 14.2577 19.7169C14.2577 19.8858 14.1432 20.0323 13.9794 20.0732L11.1471 20.7814C10.357 20.9788 9.80532 21.6856 9.80532 22.5C9.80532 23.3144 10.3571 24.0212 11.1471 24.2186L13.9794 24.9267C14.1432 24.9678 14.2576 25.1142 14.2576 25.283C14.2576 25.4856 14.0928 25.6504 13.8903 25.6504H9.62435C8.90743 25.6504 8.32629 26.2316 8.32629 26.9485C8.32629 27.6653 8.90751 28.2465 9.62435 28.2465H13.8903C15.5244 28.2465 16.8538 26.9171 16.8538 25.283C16.8538 24.0264 16.0681 22.9228 14.9089 22.5Z" fill="white"/>
			<path d="M23.2413 21.2019H20.8323V20.2758C20.8323 19.7652 21.2477 19.3497 21.7585 19.3497H25.4655C26.1825 19.3497 26.7636 18.7684 26.7636 18.0516C26.7636 17.3348 26.1824 16.7535 25.4655 16.7535H21.7585C19.8163 16.7535 18.2361 18.3337 18.2361 20.2759V24.7243C18.2361 26.6665 19.8162 28.2467 21.7585 28.2467H23.2413C25.1835 28.2467 26.7637 26.6665 26.7637 24.7243C26.7637 22.7821 25.1836 21.2019 23.2413 21.2019ZM23.2413 25.6504H21.7585C21.2478 25.6504 20.8323 25.2349 20.8323 24.7242V23.7981H23.2413C23.7519 23.7981 24.1675 24.2136 24.1675 24.7242C24.1675 25.2349 23.752 25.6504 23.2413 25.6504Z" fill="white"/>
			<path d="M33.1513 16.7534H31.6685C29.7263 16.7534 28.1462 18.3336 28.1462 20.2758V24.7242C28.1462 26.6664 29.7263 28.2466 31.6685 28.2466H33.1513C35.0936 28.2466 36.6737 26.6664 36.6737 24.7242V20.2758C36.6737 18.3336 35.0936 16.7534 33.1513 16.7534ZM34.0775 24.7242C34.0775 25.2349 33.6621 25.6504 33.1514 25.6504H31.6686C31.158 25.6504 30.7425 25.2349 30.7425 24.7242V20.2758C30.7425 19.7652 31.158 19.3496 31.6686 19.3496H33.1514C33.6621 19.3496 34.0775 19.7652 34.0775 20.2758V24.7242Z" fill="white"/>
			<path d="M23.0111 42.3975C22.6768 42.4059 22.3346 42.4059 22.0004 42.3977C21.284 42.383 20.6883 42.9464 20.6703 43.6631C20.6525 44.3798 21.219 44.9752 21.9358 44.9931C22.1225 44.9977 22.3122 45.0001 22.4999 45.0001C22.6918 45.0001 22.8858 44.9976 23.0767 44.9928C23.7934 44.9747 24.3597 44.3791 24.3416 43.6624C24.3235 42.9456 23.7256 42.3806 23.0111 42.3975Z" fill="white"/>
			<path d="M44.3754 17.2139C44.2868 16.8458 44.1874 16.4751 44.0802 16.1122C43.877 15.4246 43.1549 15.0323 42.4674 15.2353C41.7798 15.4386 41.3872 16.1607 41.5905 16.8481C41.6853 17.1689 41.7731 17.4964 41.8515 17.8217C41.9948 18.4166 42.5263 18.8162 43.1125 18.8162C43.213 18.8162 43.3153 18.8043 43.4175 18.7797C44.1143 18.612 44.5433 17.9108 44.3754 17.2139Z" fill="white"/>
			<path d="M12.9876 39.9884C12.6933 39.828 12.3996 39.6581 12.1145 39.4833C11.5034 39.1086 10.7041 39.3001 10.3294 39.9113C9.95459 40.5224 10.1463 41.3217 10.7574 41.6964C11.08 41.8943 11.4123 42.0866 11.7452 42.2679C11.9425 42.3754 12.1553 42.4265 12.3653 42.4265C12.825 42.4265 13.2706 42.1816 13.5062 41.7493C13.8493 41.1199 13.617 40.3314 12.9876 39.9884Z" fill="white"/>
			<path d="M17.8381 41.8553C17.512 41.777 17.184 41.6895 16.8634 41.5949C16.1757 41.3924 15.4541 41.7854 15.2514 42.4731C15.0487 43.1607 15.442 43.8825 16.1296 44.0851C16.4924 44.192 16.8635 44.2911 17.2324 44.3797C17.3342 44.4041 17.4361 44.4159 17.5363 44.4159C18.1227 44.4159 18.6546 44.0156 18.7975 43.4203C18.9648 42.7233 18.5353 42.0226 17.8381 41.8553Z" fill="white"/>
			<path d="M42.2592 11.7297C42.078 11.3979 41.8855 11.066 41.6874 10.7431C41.3123 10.1321 40.5129 9.9408 39.902 10.3159C39.291 10.6909 39.0998 11.4903 39.4748 12.1013C39.6502 12.387 39.8204 12.6806 39.9806 12.9742C40.2164 13.4059 40.6615 13.6503 41.121 13.6503C41.3313 13.6503 41.5446 13.5991 41.7421 13.4911C42.3713 13.1475 42.6028 12.3589 42.2592 11.7297Z" fill="white"/>
			<path d="M29.7575 42.4699C29.5547 41.7823 28.8328 41.3895 28.1452 41.5923C27.8232 41.6873 27.4957 41.7749 27.1715 41.8529C26.4745 42.0203 26.045 42.7212 26.2125 43.4182C26.3555 44.0134 26.8872 44.4135 27.4736 44.4135C27.5739 44.4135 27.6759 44.4017 27.7778 44.3772C28.145 44.2891 28.5158 44.1898 28.88 44.0823C29.5675 43.8794 29.9604 43.1574 29.7575 42.4699Z" fill="white"/>
			<path d="M43.4217 26.194C42.7244 26.027 42.0239 26.4568 41.857 27.1541C41.7791 27.4795 41.6916 27.8074 41.5972 28.1283C41.3948 28.816 41.7883 29.5375 42.476 29.7399C42.5983 29.7759 42.7216 29.7931 42.8431 29.7931C43.404 29.7931 43.9214 29.4266 44.0878 28.8611C44.1946 28.498 44.2935 28.127 44.3818 27.7587C44.5488 27.0615 44.1189 26.361 43.4217 26.194Z" fill="white"/>
			<path d="M41.7536 31.4851C41.124 31.1423 40.3356 31.3746 39.9927 32.0043C39.8325 32.2983 39.6626 32.5926 39.4873 32.8788C39.113 33.4902 39.3052 34.2894 39.9167 34.6637C40.1281 34.7932 40.3621 34.8549 40.5932 34.8549C41.03 34.8549 41.4567 34.6343 41.7015 34.2344C41.8996 33.9109 42.0918 33.5783 42.2727 33.2459C42.6156 32.6164 42.3831 31.828 41.7536 31.4851Z" fill="white"/>
			<path d="M38.7663 36.161C38.2465 35.6672 37.4249 35.6881 36.9311 36.2079C36.7004 36.4508 36.4604 36.6908 36.2177 36.9217C35.6984 37.4159 35.6779 38.2375 36.1721 38.7569C36.4274 39.0251 36.7697 39.1602 37.1128 39.1602C37.4342 39.1602 37.7562 39.0415 38.0074 38.8025C38.2814 38.5417 38.5526 38.2705 38.8132 37.9962C39.307 37.4765 39.2861 36.6549 38.7663 36.161Z" fill="white"/>
			<path d="M44.9928 21.9173C44.9748 21.2119 44.3975 20.6523 43.6958 20.6523C43.6847 20.6523 43.6734 20.6525 43.6622 20.6528C42.9454 20.6711 42.3792 21.2668 42.3975 21.9834C42.4018 22.1544 42.4041 22.3281 42.4041 22.5002C42.4041 22.6643 42.4021 22.8303 42.3981 22.9936C42.3807 23.7103 42.9477 24.3053 43.6644 24.3227C43.6751 24.323 43.6857 24.3231 43.6964 24.3231C44.3987 24.3231 44.9763 23.7624 44.9935 23.0564C44.9979 22.8723 45.0002 22.6851 45.0002 22.5001C45.0002 22.3062 44.9977 22.1101 44.9928 21.9173Z" fill="white"/>
			<path d="M34.676 39.9075C34.3012 39.2966 33.5019 39.105 32.8907 39.4798C32.6059 39.6547 32.3126 39.8245 32.0189 39.9847C31.3896 40.3281 31.1579 41.1166 31.5013 41.7459C31.7369 42.1778 32.1822 42.4225 32.6418 42.4225C32.852 42.4225 33.0651 42.3714 33.2625 42.2636C33.5945 42.0825 33.9261 41.8903 34.2483 41.6927C34.8595 41.318 35.0509 40.5187 34.676 39.9075Z" fill="white"/>
		</g>
		<defs>
			<clipPath id="clip0">
				<rect width="45" height="45" fill="white"/>
			</clipPath>
		</defs>
	</svg>
)