import React, { Component } from 'react'
import cx from 'classnames'
import { withUiContext } from '../../UiContext'
import IconVolume2 from '../../components/Common/Icons/IconVolume2'
import IconVolume2Diabled from '../../components/Common/Icons/IconVolume2Diabled'
import './BackSound.css'

class BackSound extends Component {
	state = {
		muted: true
	}
	// toggleVolume = () => {
	// 	let audio = this.audio
	// 	this.setState(function (prevState) {
	// 		if (prevState.muted) this.audio.play()
	// 		audio.volume = prevState.muted ? 0.5 : 0
	// 		return {muted: !prevState.muted}
	// 	})
	// }
	toggle = () => {
		const { ui } = this.props
		const { backAudioPaused } = ui
		ui.toggleBackAudio()
		if (!backAudioPaused) ui.enableBackAudioUserControl()
		if (backAudioPaused) ui.disableBackAudioUserControl()
	}
  render(){
  	const { muted } = this.state
  	const { ui } = this.props
	  return(
	  	<div className={cx("back-sound", ui.backAudioPaused && "back-sound_muted")}>
	    	<div className="back-sound__btn" onClick={this.toggle}>
	    		<i className="back-sound__on"><IconVolume2 /></i>
	    		<i className="back-sound__off"><IconVolume2Diabled /></i>
	    	</div>
	  	</div>
	  )
  }
}

export default withUiContext(BackSound)