import React, { Component } from 'react'
import cx from 'classnames'
import { getElementOffset } from '../../lib/utils'
import IconVolume from '../../components/Common/Icons/IconVolume'
import './Volume.css'

export default class Volume extends Component{

	state = {
		volume: 0.5,
	}

	isDragging = false

	handleMousedown = (event) => {
		this.isDragging = true;
	}
	handleMouseup = (event) => {
		this.isDragging = false;
	}
	handleMousemove = (event) => {
		if (!this.isDragging) return;
		var percent = this.getMousePosition(event.clientX);
		this.changeVolume(percent);
		this.changeFill(percent);
	}
	getMousePosition = (clientX) => {
		var windowWidth = window.innerWidth;
		var volumeWidth = this.line.offsetWidth;
		var volumeOffsetLeft = getElementOffset(this.line).left;
		var innerX = clientX - volumeOffsetLeft;
		var percent = innerX / volumeWidth;
		if (percent < 0) percent = 0;
		return percent;
	}
	handleClick = (event) => {
		var _this = this,
			percent = this.getMousePosition(event.clientX);
		this.changeFill(percent);
		this.changeVolume(percent);
	};
	changeFill = (percent) => {
		this.fill.style.width = percent * 100 + '%';
	}
	changeVolume = (volume) => {
		this.props.onVolumeChange(volume)
		this.setState({volume: volume})
	}
	handleButtonClick = () => {
		this.props.onToggleVolume()
		this.toggleVolume()
	}
	toggleVolume = () => {
		const { volume } = this.state
		volume > 0 ? this.changeVolume(0) : this.changeVolume(0.5)
	}

  render(){
  	const { volume } = this.state
  	let volumeOn = volume > 0
	  return(
	  	<div className="volume">
	    	<div className={cx("volume__btn", volumeOn && "volume__btn_on")} onClick={this.handleButtonClick}>
	    		<i className="volume__icon"><IconVolume /></i>
	    	</div>
	    	<div className="volume__line" ref={(line) => this.line = line}
	    		onClick={this.handleClick}
	    		onMouseMove={this.handleMousemove}
	    		onMouseDown={this.handleMousedown}
	    		onMouseUp={this.handleMouseup}>
	    		<div className="volume__fill" ref={(fill) => this.fill = fill}></div>
	    	</div>
	  	</div>
	  )
  }
}