import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import { withUiContext } from '../../UiContext'

class NavLink1 extends Component{
	playOverSound = () => {
    const {ui} = this.props
		ui.playOverSound()
  }
  playClickSound = () => {
    const {ui} = this.props
		ui.playClickSound()
  }
  handleMouseEnter = () => {
  	this.playOverSound()
  	if (this.props.onMouseEnter) this.props.onMouseEnter()
  }
  handleClick = () => {
  	this.playClickSound()
    const { ui, onClick } = this.props
  	if (onClick) onClick()
  }

  render(){
	  return(
	  	<NavLink onMouseEnter={this.handleMouseEnter} onClick={this.handleClick} {...this.props} />
	  )
  }
}

export default withUiContext(NavLink1)