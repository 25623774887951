import React from 'react'
import ScreenTitle from '../../components/ScreenTitle'
import OverModal from '../../components/OverModal'
import CloseBtn from '../../components/CloseBtn'
import Description from '../../components/Description'
import * as routes from '../../const/routes'

export default () => (
	<div className="_bg-inherit">
		<ScreenTitle rotate={true}>
			<span>СИСТЕМА придонных ПОДВЕСОК <i>MUDLINE</i></span>
		</ScreenTitle>
	  <OverModal className="video-screen">
			<Description>
				<CloseBtn link={routes.DETAILS_DETAIL1} transpBg={true} />
				<h1>Консервационный колпак 13-3/8” x 9-5/8”</h1> 
		  	<div className="subt"></div>
		  	<p>Консервационный колпак позволяет проводить консервацию обеих обсадных колонн за одно СПО, что позволяет сократить время на выполнение работ по разведочному бурению и имеет следующие особенности:
					<ul>
						<li>Установка консервационного колпака проводится при полном контроле ПВО</li>
						<li> Консервационный колпак предотвращает «всплытие» эксплуатационной колонны, что повышает надёжность выполнения работ по консервации скважины</li>
						<li>Консервационный колпак позволяет проводить сброс давления из эксплуатационной колонны под защитой межтрубного пространства 13 3/8” и 9-5/8” обсадных колонн, что увеличивает безопасность и надёжность проведения операции </li>
					</ul>
				</p>
			</Description>
	  </OverModal>
  </div>
)
