import React from 'react'
import ScreenTitle from '../../components/ScreenTitle'
import OverModal from '../../components/OverModal'
import CloseBtn from '../../components/CloseBtn'
import Description from '../../components/Description'
import * as videos from '../../const/videos'

export default () => (
	<div className="_bg-inherit">
		<ScreenTitle rotate={true}>
			<span>СИСТЕМА придонных ПОДВЕСОК <i>MUDLINE</i></span>
		</ScreenTitle>
	  <OverModal className="video-screen">
			<Description>
				<CloseBtn link="/mudline/web" noBg={true} />
				<h1>Система придонных подвесок</h1> 
				<div className="subt">(донное подвесное оборудование)</div>
				Предназначена для удержания части веса обсадных колонн ниже уровня дна моря во время бурения и строительства подводных разведочных скважин.
				<ul>
					<li>Конструкция скважины 30” х 20” х 13-3/8” х 9-5/8” или иное по требованию Заказчика.</li>
					<li>Рабочая среда — нефть, газ, конденсат, вода пластовая.</li>
					<li>Номинальное рабочее давление — 15 000 psi.</li>
					<li>Температурный класс — L-U.</li>
					<li>Номинальная глубина моря — от 5 до 135 м.</li>
					<li>Проектная глубина скважины по вертикали (по стволу) — в соответствии с требованиями Заказчика.</li>
					<li>Класс материала — DD.</li>
				</ul>
				<h2>Зачем нужен продукт «Гусар-Plexus» нефтегазовой отрасли России?</h2>
				<ul>
					<li>Система придонных подвесок позволяет отходить от скважины на любом этапе бурения, что позволяет проводить бурение в тяжелой ледовой обстановке и отводить буровую платформу при опасности прохождения ледовых полей.</li>
					<li>Конструкция системы придонных подвесок Гусар-Plexus (наличие дополнительных центровочных поверхностей) позволяет увеличить надежность при проведении операции возврата к скважине.</li>
					<li>Бурение и спуск оборудования производится без проведения промежуточных операций монтажа/демонтажа блока ПВО, что сокращает время бурения до 5 дней.</li>
					<li>Бурение и спуск оборудования производится под постоянной защитой ПВО, что повышает безопасность работы на буровой.</li>
					<li>Экономическая эффективность за счет локализации производства на территории РФ.</li>
				</ul>
			</Description>
	  </OverModal>
  </div>
)