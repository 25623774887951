import React, { Component } from 'react'
import Video from '../../components/Video'
import ScreenTitle from '../../components/ScreenTitle'
import OverModal from '../../components/OverModal'
import * as videos from '../../const/videos'
import * as routes from '../../const/routes'

const video = videos.UNDERWATER

export default class Underwater extends Component{

	onEnded = () => {
		this.props.history.push(routes.SCHEME)
	}

  render(){
	  return(
	  	<div className="_bg-inherit">
				<ScreenTitle rotate={true}>
					<span>БУРОВАЯ ПЛАТФОРМА</span>
				</ScreenTitle>
			  <OverModal className="video-screen">
			  	<Video video={video} closeLink={routes.HOME} onEnded={this.onEnded} loop={true} />
			  </OverModal>
		  </div>
	  )
  }
}