import React from 'react'
import Backdrop from '../../components/Backdrop'
import ScreenTitle from '../../components/ScreenTitle'
import Gallery from '../../components/Gallery'
import OverModal from '../../components/OverModal'

export default () => (
	<div className="_bg-inherit">
		<ScreenTitle rotate={true}>
			<span>О ЗАВОДЕ <i>ГУСАР</i></span>
		</ScreenTitle>
	  <OverModal className="gallery-screen">
	  	<Gallery />
	  </OverModal>
  </div>
)