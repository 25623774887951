import React from 'react'
import Video from '../../components/Video'
import ScreenTitle from '../../components/ScreenTitle'
import OverModal from '../../components/OverModal'
import * as videos from '../../const/videos'
import * as routes from '../../const/routes'

const video = videos.SCHEME

export default () => (
	<div className="_bg-inherit">
		<ScreenTitle rotate={true}>
			<span>СХЕМА РАБОТЫ <i>MUDLINE</i></span>
		</ScreenTitle>
	  <OverModal className="video-screen">
	  	<Video video={video} closeLink={routes.HOME} loop={true} />
	  </OverModal>
  </div>
)