import React from 'react'
import cx from 'classnames'
import NavLink from '../../components/NavLink'
import IconClose from '../Common/Icons/IconClose'
import Backdrop from '../../components/Backdrop'
import './CloseBtn.css'

export default ({link, className, out, noBg, transpBg, ...props}) => (
	<div className={cx("close-btn", out && "close-btn_out", noBg && "close-btn_no-bg", transpBg && "close-btn_transpbg" )}>
		{ (!noBg && !transpBg) &&
			<Backdrop>
		  <NavLink className="close-btn__inner" to={link}>
		  	<IconClose />
		  </NavLink>
		  </Backdrop>
		}
	  { (noBg || transpBg) &&
	  	<NavLink className="close-btn__inner" to={link}>
		  	<IconClose />
		  </NavLink>
	  }
  </div>
)