import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { isIE } from './lib/utils'
import './index.css';
// const remote = require("electron").remote;

// import { remote } from "electron"

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

if (isIE()) {
	document.body.classList.add("_isEdge")
}

setTimeout(function() {
	let modals = document.getElementsByClassName("overlay-modal-outer")
	Array.prototype.forEach.call(modals, function (modal) {
		modal.classList.add("_show-modal")
	})
}, 100);



// document.addEventListener("keydown", event => {
//     switch (event.key) {
//         case "Escape":
//             if (remote.getCurrentWindow().isFullScreen()) {
//                 remote.getCurrentWindow().setFullScreen(false);
//             }
//             break;
//          }
// });