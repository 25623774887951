import React, { Component } from 'react'
import './MobilePlaceholder.css'

export default class MobilePlaceholder extends Component{
  render(){
	  return(
	  	<div className="mobile-placeholder">
	  		<div className="mobile-placeholder__inner">
	  			<div className="mobile-placeholder__logo"></div>
	  			<div className="mobile-placeholder__text">
	    			Презентация работает  при разрешении экрана <span className="_nowrap">1920х1080 пикселей</span> и выше. 
						Ваше устройство не поддерживает данное <span className="_nowrap">разрешение экрана.</span>
	    		</div>
	    	</div>
	  	</div>
	  )
  }
}