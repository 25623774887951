import React from 'react'
import cx from 'classnames'
import NavLink from '../../components/NavLink'
import IconBorder from '../../components/Common/Icons/IconBorder'
import './DescriptionBtn.css'

export default ({link, detailDescBtn, ...props}) => (
  <div className={cx("description-btn", detailDescBtn && "description-btn_detail")}>
  	<NavLink to={link} className="description-btn__inner">
  		<IconBorder />
  		Описание
  	</NavLink>
  </div>
)