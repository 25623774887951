import React, { Component } from 'react'
import cx from 'classnames'
import Backdrop from '../../components/Backdrop'
import CloseBtn from '../../components/CloseBtn'
import Item from '../../components/Item'
import Modal from '../../components/Modal'
import ScreenTitle from '../../components/ScreenTitle'
import DescriptionBtn from '../../components/DescriptionBtn'
import * as routes from '../../const/routes'
import * as videos from '../../const/videos'

import Img from '../../assets/img/detail5.png'

const Video = videos.DETAIL5_360

export default class Detail5 extends Component{
	componentDidMount() {
		this.videoSrc = document.getElementById("video_url_" + Video.name).value
	}
  render(){
	  return(
	  	<div className="_bg-inherit">
		  	<ScreenTitle>
					<span>СИСТЕМА придонных ПОДВЕСОК <i>MUDLINE</i></span>
				</ScreenTitle>
				<Modal className={cx( "detail-screen")}>
				  <Backdrop>
				  	<Item wide={true} 
				  		descLink={routes.DETAIL5_DESC} 
				  		name="Спуско-подъемный инструмент трубодержателя (подвески) обсадной колонны 20”" 
				  		img={Img} 
				  		video={this.videoSrc} ></Item>
				  </Backdrop>
				  <CloseBtn link="/mudline/details" />
				  <DescriptionBtn link={routes.DETAIL5_DESC} detailDescBtn={true} />
		  	</Modal>
		  </div>
	  )
  }
}