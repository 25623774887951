import React, { Component } from 'react'
import NavLink from '../../components/NavLink'
import cx from 'classnames'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import './Video.css'
import VideoContainer from '../../components/VideoContainer'
import VideoControls from '../../components/VideoControls'
import VideoProgressBar from '../../components/VideoProgressBar'
import Throbber from '../../components/Throbber'
import PlayBtn from '../../components/PlayBtn'
import CloseBtn from '../../components/CloseBtn'
import { withUiContext } from '../../UiContext'
import { isMobile } from '../../lib/utils'

class VideoPlayer extends Component{
	state = {
		loaded: false,
		showThrobber: false,
		showTitle: false,
		showPlayBtn: false,
		paused: false,
	}
	componentDidMount() {
		this.videoEl.addEventListener('playing', this.handlePlaying)
		this.videoEl.addEventListener('waiting', this.handleWaiting)

		if (isMobile()) {
			this.play()
			this.setState({loaded: true})
			return
		}

		this.videoEl.addEventListener('canplay', this.handleCanplay)
		this.videoEl.addEventListener('timeupdate', this.handleTimeupdate)
	}
	componentWillUnmount() {
		const { ui } = this.props
		this.videoEl.removeEventListener('canplay', this.handleCanplay)
		this.videoEl.removeEventListener('waiting', this.handleWaiting)
		this.videoEl.removeEventListener('playing', this.handlePlaying)
		this.videoEl.removeEventListener('timeupdate', this.handleTimeupdate)

		if (!ui.isBackAudioMutedByUser) ui.playBackAudio()
	}
	componentDidUpdate(prevProps) {
		if (prevProps.isMapPlaying && !this.props.isMapPlaying) {
			this.videoEl.play()
		}
	}
	handleCanplay = () => {
		this.setState({loaded: true})
		this.play()
	}
	play = () => {
		const { ui } = this.props
		let playPromise = this.videoEl.play()
		if (playPromise !== 'undefined') {
	    playPromise.then(() => {
	    	this.hidePlayBtn()
	    	this.setState({paused: false})
	    	ui.pauseBackAudio()
	    })
	    .catch(error => {
	    	console.log('Video error')  
	    })
	  }
	}
	pause = () => {
		this.videoEl.pause()
		this.setState({paused: true, showPlayBtn: true})
	}
	togglePlay = () => {
		const videoEl  = this.videoEl
		videoEl.paused ? this.play() : this.pause()
		this.hideThrobber()
	}
	handleEnded = () => {
		const { showNavigationOnEnded = true, ui, onEnded, loop, } = this.props
		if (showNavigationOnEnded && !ui.navExpanded) ui.toggleNav() 
		if (onEnded) onEnded()
		if (!ui.isBackAudioMutedByUser) ui.playBackAudio()
	}
	handleWaiting = () => {
		this.setState({showThrobber: true})
	}
	handlePlaying = () => {
		this.hideThrobber()
		setTimeout(() => {
			this.hideTitle()
		}, 3000)
	}
	hideThrobber = () => {
		const { showThrobber } = this.state
		if (showThrobber) {
			this.setState({showThrobber: false})
		}
	}
	hideTitle = () => {
		const { showTitle } = this.state
		if (showTitle) this.setState({showTitle: false})
	}
	handleTimeupdate = () => {
		const seconds = this.videoEl.currentTime
		const duration = this.videoEl.duration
		if (this.props.onTimeUpdate) {
			this.props.onTimeUpdate(seconds, duration)
		}
	}
	showPlayBtn = () => {
		const { showPlayBtn } = this.state
		if (!showPlayBtn) {
			this.setState({showPlayBtn: true})
		}
	}
	hidePlayBtn = () => {
		const { showPlayBtn } = this.state
		if (showPlayBtn) {
			this.setState({showPlayBtn: false})
		}
	}

  render(){
  	const { loaded, showThrobber, showTitle, showPlayBtn, paused } = this.state
  	const { 
  		video, 
	  	title,
	  	closeLink,
	  	loop,
	  } = this.props

	  let videoSrc = video
	  if (window.innerWidth < 1281) {
	  	let varr = videoSrc.split('.')
	  	varr[0] = varr[0]+'_tab'
	  	videoSrc = varr.join('.')
	  	
	  }

	  return(
	  	<div className="video">
	  		<CloseBtn out={true} noBg={true} link={closeLink} />
	  		<TransitionGroup>
	        {title && showTitle &&
	          <CSSTransition timeout={500} classNames="fade">
							<div className="video-title">{title}</div>
						</CSSTransition>
		    	}
	    	</TransitionGroup>
	    	<VideoContainer>
	    		<video src={videoSrc} playsInline webkit-playsinline="true"
		  			ref={(videoEl) => this.videoEl = videoEl}
		  			loop={loop}
		  			onClick={this.togglePlay}
		  			onEnded={this.handleEnded} />
	    	</VideoContainer>
	    	<VideoControls video={this.videoEl} />
	    	<VideoProgressBar video={this.videoEl} onPauseBtnClick={this.togglePlay} paused={paused} />
	    	<TransitionGroup>
	        {!loaded &&
	          <CSSTransition timeout={500} classNames="fade">
							<Throbber />
						</CSSTransition>
		    	}
	        {showThrobber &&
	          <CSSTransition timeout={500} classNames="fade">
							<Throbber />
						</CSSTransition>
		    	}
		    	{showPlayBtn &&
	          <CSSTransition timeout={500} classNames="fade">
							<PlayBtn onClick={this.play} />
						</CSSTransition>
		    	}
	    	</TransitionGroup>
	  	</div>
	  );
  }
}

export default withUiContext(VideoPlayer)