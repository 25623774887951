import React, { Component } from 'react'
import NavLink from '../../components/NavLink'
import cx from 'classnames'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import './Video.css'
import { withUiContext } from '../../UiContext'
import VideoPlayer from './VideoPlayer'
import VimeoPlayer from './VimeoPlayer'

class Video extends Component{

  render(){
  	const { 
  		video, 
	  	autoshow = false,
	  	onEnded = false,
	  	title = '',
	  	isMapPlaying = false,
	  	onTimeUpdate,
	  	closeLink,
	  	loop,
	  } = this.props
	  const { file, vimeo_id } = video

	  if (vimeo_id && vimeo_id.length) {
		  return(
		  	<VimeoPlayer vimeoId={vimeo_id} {...this.props} />
		  )
		} else {
		  return(
		  	<VideoPlayer 
		  		video={file} 
		  		autoshow={autoshow} 
		  		onEnded={onEnded} 
		  		title={video.title} 
		  		isMapPlaying={isMapPlaying}
		  		closeLink={closeLink}
		  		loop={loop}
		  		onTimeUpdate={onTimeUpdate} />
		  )
		}
  }
}

export default withUiContext(Video)