import React from 'react'
import cx from 'classnames'
import './OverModal.css'

export default ({className, children, ...props}) => (
	<div className={cx("overlay-modal-outer", className)}>
		  <div className="overlay-modal">
		  	{children}
		  </div>
  </div>
)