import React from 'react'
import ScreenTitle from '../../components/ScreenTitle'
import OverModal from '../../components/OverModal'
import CloseBtn from '../../components/CloseBtn'
import Description from '../../components/Description'
import * as routes from '../../const/routes'

export default () => (
	<div className="_bg-inherit">
		<ScreenTitle rotate={true}>
			<span>СИСТЕМА придонных ПОДВЕСОК <i>MUDLINE</i></span>
		</ScreenTitle>
	  <OverModal className="video-screen">
			<Description>
				<CloseBtn link={routes.DETAILS_DETAIL3} noBg={true} />
				<h1>Посадочное кольцо 30”</h1> 
		  	<div className="subt"></div>
		  	<p>Посадочное кольцо представляет собой упор, поддерживающий суммарный вес всех обсадных колонн.</p>
			</Description>
	  </OverModal>
  </div>
)
